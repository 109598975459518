// src/Context/ProductContext.tsx

import React, { createContext, useCallback, useContext, useState } from "react";
import { Product, FetchProductsParams} from "../admin/auth/types";
import { configFetchProductById, configTestListProducts } from "../admin/auth/aws-config";
import { isValidCategory } from "../utils/typeGaurds";

interface ProductContextType {
  fetchProducts: (params?: FetchProductsParams) => Promise<Product[]>;
  fetchProductById: (productId: string) => Promise<Product | null>;
  isFetching: boolean;

}

const defaultContext: ProductContextType = {
  fetchProducts: async (params?: FetchProductsParams): Promise<Product[]> => {
    throw new Error("Context not implemented");
  },
  fetchProductById: async (id: string): Promise<Product | null> => {
    throw new Error("Context not implemented");
  },
  isFetching: false,
  
};

export const ProductContext = createContext<ProductContextType | undefined>(
  defaultContext
);

export const ProductProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false); // Optional: To manage loading states

  const fetchProducts = useCallback(
    async ({
      category,
      subcategories,
      sortOrder = "asc",
      searchKeyword = "",
      page,
      limit,
    }: FetchProductsParams = {}): Promise<Product[]> => {
      console.log("fetchProducts called with:", {
        category,
        subcategories,
        sortOrder,
        searchKeyword,
        page,
        limit,
      });

      if (category && !isValidCategory(category)) {
        throw new Error(`Invalid category: ${category}`);
      }

      try {
        setIsFetching(true);

        // Initialize URL object with the base API endpoint
        const url = new URL(configTestListProducts.API_ENPOINT_TEST_LIST_PRODUCTS);

        // Append query parameters based on the provided params
        if (category && category !== "All") {
          url.searchParams.append("category", category);
        }

        if (subcategories && subcategories.length > 0) {
          // Assuming the API expects a comma-separated string for subcategories
          url.searchParams.append("subcategories", subcategories.join(","));
          // If the API expects multiple subcategory parameters, use the following:
          // subcategories.forEach((sub) => url.searchParams.append("subcategories", sub));
        }

        if (sortOrder) {
          url.searchParams.append("sortOrder", sortOrder);
        }

        if (searchKeyword) {
          url.searchParams.append("searchKeyword", searchKeyword);
        }

        if (page !== undefined) {
          url.searchParams.append("page", page.toString());
        }

        if (limit !== undefined) {
          url.searchParams.append("limit", limit.toString());
        }

        console.log("Constructed URL:", url.toString());

        const response = await fetch(url.toString(), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // No Authorization header needed as per your requirement
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch products. Status: ${response.status}, Message: ${errorText}`);
        }

        const products: Product[] = await response.json();
        console.log("Fetched products:", products);
        return products;
      } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const fetchProductById = useCallback(
    async (id: string): Promise<Product | null> => {
      console.log("fetchProductById called with ID:", id);

      try {
        setIsFetching(true);

        const endpoint = configFetchProductById.API_ENDPOINT_FETCH_PRODUCT_BY_ID(id);
        const url = new URL(endpoint);

        const response = await fetch(url.toString(), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Product not found.");
          } else {
            const errorText = await response.text();
            throw new Error(`Failed to fetch product. Status: ${response.status}, Message: ${errorText}`);
          }
        }

        const product: Product = await response.json();
        console.log("Fetched Product:", product);
        return product;
      } catch (error) {
        console.error("Error fetching product by ID:", error);
        throw error;
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  return (
    <ProductContext.Provider value={{ fetchProducts, fetchProductById, isFetching }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProducts = (): ProductContextType => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};
