import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { PaymentIntent } from "@stripe/stripe-js";

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(
    null
  );
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);

  const handlePaymentSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe has not loaded yet.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const { error: confirmError, paymentIntent: pi } =
      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/success`,
        },
        redirect: "if_required",
      });

    if (confirmError) {
      setError(
        confirmError.message || "An error occurred during payment confirmation."
      );
    } else if (pi) {
      setPaymentIntent(pi);
      navigate("/success", { state: { paymentIntent: pi } });
    } else {
      setError("PaymentIntent is undefined.");
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (paymentIntent) {
      console.log("PaymentIntent ID:", paymentIntent.id);
      console.log("PaymentIntent Status:", paymentIntent.status);
    }
  }, [paymentIntent]);

  return (
    <form onSubmit={handlePaymentSubmit}>
      <Box sx={{ mt: 2, mb: 2 }}>
        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}

        <Typography variant="h6" gutterBottom>
          Shipping Address
        </Typography>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "gray.main",
            borderRadius: "4px",
            p: 2,
            mb: 2,
          }}
        >
          <AddressElement
            options={{
              mode: "shipping",
              allowedCountries: ["US", "CA"],
            }}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={billingSameAsShipping}
              onChange={(e) => setBillingSameAsShipping(e.target.checked)}
              name="billingSameAsShipping"
              color="primary"
            />
          }
          label="Billing address same as shipping address"
        />

        {!billingSameAsShipping && (
          <>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Billing Address
            </Typography>
            <Box
              sx={{
                border: "1px solid",
                borderColor: "cadetGray.main",
                borderRadius: "4px",
                p: 2,
              }}
            >
              <AddressElement
                options={{
                  mode: "billing",
                  allowedCountries: ["US", "CA"],
                }}
              />
            </Box>
          </>
        )}

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Payment Details
        </Typography>
        <Box sx={{ border: "1px solid #c4c4c4", borderRadius: "4px", p: 2 }}>
          <PaymentElement options={{ layout: "tabs" }} />
        </Box>
      </Box>

      {paymentIntent && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2">
            Payment ID: {paymentIntent.id}
          </Typography>
          <Typography variant="body2">
            Status: {paymentIntent.status}
          </Typography>
        </Box>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
        sx={{
          py: 1.5,
          fontSize: "1rem",
          mt: 3,
          backgroundColor: "rose.main",
          "&:hover": {
            backgroundColor: "rose.light",
          },
        }}
      >
        {isSubmitting ? <CircularProgress size={24} /> : "Confirm Payment"}
      </Button>
    </form>
  );
};

export default PaymentForm;
