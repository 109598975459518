// src/Components/Products/ProductDetails.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Grid,
  Paper,
  IconButton,
  TextField,
  SelectChangeEvent, // Import SelectChangeEvent
} from "@mui/material";
import { Product, Size, Price, CartItem } from "../../admin/auth/types";
import { useCart } from "../../Context/CartContext";
import { useProducts } from "../../Context/ProductContext"; // Import the custom hook
import { toast } from "react-toastify";
import { Add, Remove } from "@mui/icons-material";

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToCart } = useCart();
  const { fetchProductById } = useProducts(); // Destructure the fetchProductById function
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedSize, setSelectedSize] = useState<Size | null>(null);

  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [availableSizes, setAvailableSizes] = useState<Size[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const theme = useTheme();

  useEffect(() => {
    const getProduct = async () => {
      if (!id) {
        setError("No product ID provided.");
        return;
      }
  
      setIsLoading(true);
      setError(null);
      try {
        const fetchedProduct = await fetchProductById(id);
        setProduct(fetchedProduct);
        console.log("Fetched Product in ProductDetail:", fetchedProduct);
  
        // Initialize subcategory and size states
        if (fetchedProduct) {
          const { subcategories, sizes } = fetchedProduct;
  
          if (subcategories && subcategories.length > 0) {
            setSelectedSubcategory(subcategories[0]); // Default to first subcategory
  
            const subcategorySizes = sizes.filter((size) =>
              isSizeRelevant(subcategories[0], size)
            );
            setAvailableSizes(subcategorySizes);
  
            setDefaultSize(subcategorySizes);
          } else {
            setSelectedSubcategory(null);
            setAvailableSizes(sizes);
            setDefaultSize(sizes);
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
          console.error("Error fetching product:", err.message);
        } else {
          setError("An unknown error occurred.");
          console.error("Unknown error fetching product");
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    getProduct();
  }, [id, fetchProductById]);

  // Function to determine if a size is relevant to a subcategory
  const isSizeRelevant = (subcategory: string, size: Size): boolean => {
    // Implement logic based on your business rules
    // Example logic:
    if (subcategory === "Shirts") {
      return ["XS", "S", "M", "L", "XL"].includes(size.size || "");
    } else if (subcategory === "Hoodies") {
      return ["S", "M", "L", "XL", "XXL"].includes(size.size || "");
    }
    // Add more conditions as needed
    return true;
  };

  // Function to set the default size based on specific logic
  const setDefaultSize = (subcategorySizes: Size[]) => {
    if (subcategorySizes.length > 0) {
      // Example logic: Prefer "Sticker" size if available
      const stickerSize = subcategorySizes.find((size) => size.size === "Sticker");
      if (stickerSize) {
        setSelectedSize(stickerSize);
      } else {
        setSelectedSize(subcategorySizes[0]);
      }
    } else {
      setSelectedSize(null);
    }
  };

  // Handler for subcategory change
  const handleSubcategoryChange = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const newSubcategory = e.target.value;
    setSelectedSubcategory(newSubcategory);

    if (product) {
      // Filter sizes based on the selected subcategory
      const subcategorySizes = product.sizes.filter((size) =>
        isSizeRelevant(newSubcategory, size)
      );

      setAvailableSizes(subcategorySizes);
      setDefaultSize(subcategorySizes);
    }
  };

  // Handler for size change
  const handleSizeChange = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const sizeValue = e.target.value;
    const selected = availableSizes.find((s) => s.size === sizeValue);
    setSelectedSize(selected || null);
  };

  const handleAddToCart = () => {
    if (!product) {
      toast.error("Product information is not available.");
      return;
    }
  
    if (product.sold) {
      toast.error("This product is sold out.");
      return;
    }
  
    // Safeguard: Ensure subcategories is defined and is an array
    if (Array.isArray(product.subcategories) && product.subcategories.length > 1 && !selectedSubcategory) {
      toast.error("Please select a subcategory before adding to cart.");
      return;
    }
  
    if (!selectedSize) {
      toast.error("Please select a size before adding to cart.");
      return;
    }
  
    const cartItem: CartItem = {
      id: product.id,
      title: product.title,
      price: selectedSize.price.amount ?? 0,
      imageUrl: product.imageUrl,
      selectedSubCategory: selectedSubcategory || "",
      selectedSize: selectedSize!,
      selectedPrice: {
        amount: selectedSize.price.amount ?? 0,
        currency: selectedSize.price.currency,
      },
      quantity: quantity,
    };
  
    addToCart(cartItem);
    toast.success(`${product.title} has been added to your cart!`);
  };
  

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!product) {
    return null;
  }

  // Determine selected price
  let selectedPrice: Price | null = null;

  if (selectedSize) {
    selectedPrice = {
      amount: selectedSize.price.amount ?? 0,
      currency: selectedSize.price.currency,
    };
  }

  return (
    <Box
      sx={{
        mt: 8,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.lightCyan.light,
        minHeight: "100vh",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Image and Title */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: theme.spacing(2),
              textAlign: "center",
            }}
          >
            <img
              src={product.imageUrl}
              alt={product.title}
              onError={(e) => {
                (e.target as HTMLImageElement).src =
                  "/path-to-placeholder-image.jpg"; // Replace with your placeholder image path
              }}
              style={{
                width: "100%",
                maxHeight: "400px",
                objectFit: "contain",
                borderRadius: theme.shape.borderRadius,
              }}
            />
          </Paper>
        </Grid>

        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: theme.palette.lightBlue.dark }}
            >
              {product.title}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: theme.palette.text.secondary }}
            >
              {product.description}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.text.primary }}
            >
              Category: {product.category}
            </Typography>

            {/* Display Sold Out Message */}
            {product.sold && (
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mt: 2, color: theme.palette.error.main }}
              >
                Sold Out
              </Typography>
            )}

            {/* Subcategory Selector */}
            {product.subcategories && product.subcategories.length > 0 && (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  "& .MuiSelect-select": {
                    color: theme.palette.lightBlue.dark, // Text color inside Select
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.lightBlue.main, // Default outline color
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.rose.main, // Hover outline color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.lightBlue.dark, // Focused outline color
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.lightBlue.dark, // Arrow icon color
                  },
                }}
              >
                <InputLabel id="subcategory-selector-label">
                  Select Subcategory
                </InputLabel>
                <Select
                  labelId="subcategory-selector-label"
                  id="subcategory-selector"
                  value={selectedSubcategory || ""}
                  onChange={handleSubcategoryChange}
                  label="Select Subcategory"
                  aria-label="Select product subcategory"
                >
                  {product.subcategories.map((subcategory, index) => (
                    <MenuItem
                      key={`${product.id}-subcategory-${subcategory}-${index}`}
                      value={subcategory}
                      sx={{
                        color: theme.palette.lightBlue.dark, // Text color in dropdown
                      }}
                    >
                      {subcategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Size Selector */}
            {availableSizes.length > 0 && (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  "& .MuiSelect-select": {
                    color: theme.palette.lightBlue.dark, // Text color inside Select
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.lightBlue.main, // Default outline color
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.rose.main, // Hover outline color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.lightBlue.dark, // Focused outline color
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.lightBlue.dark, // Arrow icon color
                  },
                }}
              >
                <InputLabel id="size-selector-label">Select Size</InputLabel>
                <Select
                  labelId="size-selector-label"
                  id="size-selector"
                  value={selectedSize?.size || ""}
                  onChange={handleSizeChange}
                  label="Select Size"
                  aria-label="Select product size or sticker"
                >
                  {availableSizes.map((size, index) => (
                    <MenuItem
                      key={`${product.id}-size-${size.size}-${index}`}
                      value={size.size}
                      sx={{
                        color: theme.palette.lightBlue.dark, // Text color in dropdown
                      }}
                    >
                      {size.size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Display Selected Price */}
            {selectedPrice && (
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mt: 2, color: theme.palette.lightBlue.dark }}
              >
                Price: {formatPrice(selectedPrice.amount, selectedPrice.currency)}
              </Typography>
            )}

            {/* Add to Cart Button */}
            <Button
              variant="contained"
              disabled={product.sold}
              fullWidth
              sx={{ mt: 3, bgcolor: theme.palette.rose.main, "&:hover": { backgroundColor: theme.palette.rose.light }, }}
              onClick={handleAddToCart}
            >
              {product.sold ? "Sold Out" : "Add to Cart"}
            </Button>
          </Box>
          {/* Quantity Selector */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <IconButton
              onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
              aria-label="Decrease quantity"
            >
              <Remove />
            </IconButton>
            <TextField
              value={quantity}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                setQuantity(value >= 1 ? value : 1);
              }}
              inputProps={{ min: 1, style: { textAlign: "center" } }}
              sx={{ width: 60 }}
              variant="outlined"
              size="small"
            />
            <IconButton
              onClick={() => setQuantity((prev) => prev + 1)}
              aria-label="Increase quantity"
            >
              <Add />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// Helper function to format price
function formatPrice(amount: number, currency: string): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  }).format(amount);
}

export default ProductDetail;
