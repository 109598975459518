import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";

const FullScreenImageDialog: React.FC<{
  imageUrl: string;
  alt: string;
  open: boolean;
  onClose: () => void;
}> = ({ imageUrl, alt, open, onClose }) => {
  // Clicking on the full-screen image will close the dialog
  const handleImageClick = () => {
    onClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogContent sx={{ p: 0, backgroundColor: "black" }}>
        <img
          src={imageUrl}
          alt={alt}
          onClick={handleImageClick}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            cursor: "pointer", // change cursor to pointer on hover
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "black",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <Button onClick={onClose} sx={{ color: "white" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullScreenImageDialog;
