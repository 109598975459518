import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { Box, Typography, CircularProgress, Button, Container } from "@mui/material";
import { PaymentIntent } from "@stripe/stripe-js";
import { useCart } from "../Context/CartContext";
import ResponsiveAppBar from "../Components/Appbar/AppBar";
import Footer from "./Footer";

const PaymentStatus: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const { clearCart } = useCart();
  const hasClearedCartRef = useRef(false);

  const [message, setMessage] = useState<string>("Loading...");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Helper function to handle the PaymentIntent status
  const handlePaymentIntent = (paymentIntent: PaymentIntent) => {
    switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Payment status: succeeded! Thank you for your purchase.");
        if (!hasClearedCartRef.current) {
          clearCart();
          hasClearedCartRef.current = true;
        }
        break;
      case "processing":
        setMessage("Payment status: processing. Please wait.");
        break;
      case "requires_payment_method":
        setMessage("Payment status: requires payment method. Please try again.");
        break;
      default:
        setMessage("Payment status: unknown.");
        break;
    }
  };

  // Async function to fetch the PaymentIntent
  const fetchPaymentIntent = async (clientSecret: string) => {
    if (!stripe) {
      setMessage("Stripe is not initialized. Please refresh the page.");
      setIsLoading(false);
      return;
    }

    try {
      const result = await stripe.retrievePaymentIntent(clientSecret);
      if (result.error) {
        setMessage(`Error: ${result.error.message}`);
      } else if (result.paymentIntent) {
        handlePaymentIntent(result.paymentIntent);
      } else {
        setMessage("Payment status: unknown.");
      }
    } catch (error) {
      console.error("Error retrieving PaymentIntent:", error);
      setMessage("An unexpected error occurred while retrieving payment status.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const clientSecret = query.get("payment_intent_client_secret");
    const paymentIntentFromState = location.state?.paymentIntent;

    if (clientSecret) {
      fetchPaymentIntent(clientSecret);
    } else if (paymentIntentFromState) {
      handlePaymentIntent(paymentIntentFromState);
      setIsLoading(false);
    } else {
      setMessage("Missing payment details. Unable to retrieve payment status.");
      setIsLoading(false);
    }
  }, [stripe, location.search, location.state]);

  const handleReturnToHome = () => {
    navigate("/");
  };

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "80vh",
            textAlign: "center",
            p: 2,
          }}
        >
          <Typography
            variant="h4"
            className="alpine-text"
            sx={{
              color: "grayCadet.light",
              textAlign: "center",
              pt: 10,
              fontWeight: 300,
              letterSpacing: ".3rem",
            }}
          >
            Payment Confirmation
          </Typography>
          {isLoading ? (
            <CircularProgress sx={{ mt: 4 }} />
          ) : (
            <Typography
              variant="h6"
              className="alpine-text"
              sx={{
                color: message.includes("succeeded")
                  ? "green"
                  : message.includes("Error")
                  ? "error.main"
                  : "text.primary",
                textAlign: "center",
                mt: 4,
              }}
            >
              {message}
            </Typography>
          )}
          {!isLoading && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReturnToHome}
              sx={{ mt: 4 }}
            >
              Return to Home
            </Button>
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default PaymentStatus;
