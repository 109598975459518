import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import { CheckoutEvent, ShippingDetails } from "../../admin/auth/types";
import { useCart } from "../../Context/CartContext";
import OrderSummary from "./OrderSummary";

interface CheckoutFormProps {
  onCheckout: (event: CheckoutEvent) => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onCheckout }) => {
  const { cartItems } = useCart();

  // State for user details
  const [userDetails, setUserDetails] = useState<{
    name: string;
    email: string;
  }>({
    name: "",
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleUserDetailsSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (cartItems.length === 0) {
      setError("Your cart is empty.");
      return;
    }

    // Simple validation
    if (!userDetails.name || !userDetails.email) {
      setError("Please provide your name and email.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    // Create a CheckoutEvent without address details (address will be collected on PaymentForm)
    const checkoutEvent: CheckoutEvent = {
      items: cartItems.map((item) => ({
        id: item.id,
        name: item.title,
        subcategory: item.selectedSubCategory,
        size: item.selectedSize?.size,
        currency: item.selectedPrice.currency,
        quantity: item.quantity,
        price: item.selectedPrice.amount,
        imageUrl: item.imageUrl,
      })),
      customer_email: userDetails.email,
      shipping_details: { address: {}, name: userDetails.name } as ShippingDetails,
      billing_details: { address: {}, name: userDetails.name } as ShippingDetails,
    };

    onCheckout(checkoutEvent);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleUserDetailsSubmit}>
      {error && (
        <Typography variant="body2" color="error" gutterBottom>
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <OrderSummary />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Full Name"
            name="name"
            fullWidth
            required
            variant="outlined"
            value={userDetails.name}
            onChange={(e) =>
              setUserDetails({ ...userDetails, name: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            required
            variant="outlined"
            value={userDetails.email}
            onChange={(e) =>
              setUserDetails({ ...userDetails, email: e.target.value })
            }
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
        sx={{
          py: 1.5,
          fontSize: "1rem",
          mt: 3,
        }}
      >
        {isSubmitting ? <CircularProgress size={24} /> : "Continue to Payment"}
      </Button>
    </form>
  );
};

export default CheckoutForm;
