// src/components/Cart.tsx

import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { CartItem } from "../../admin/auth/types";

export const calculateTotal = (cart: CartItem[]): number => {
  return cart.reduce((sum, item) => {
    const amount = item.selectedPrice.amount ?? 0;
    return sum + amount * item.quantity;
  }, 0);
};

const Cart: React.FC = () => {
  const {
    cartItems,
    removeFromCart,
    clearCart,
    incrementQuantity,
    decrementQuantity,
  } = useCart();

  const navigate = useNavigate();
  const totalPrice = calculateTotal(cartItems);

  const handleProceedToCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Card variant="outlined" sx={{ mt: 3 }}>
      <CardContent>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            letterSpacing: ".3rem",
            textAlign: "center",
            mb: 3,
            mt: 8,
          }}
        >
          Your Cart
        </Typography>

        {/* If cart is empty */}
        {cartItems.length === 0 && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: "center", mt: 4 }}
          >
            Cart is currently empty.
          </Typography>
        )}

        {/* If cart has items */}
        {cartItems.length > 0 && (
          <>
            {cartItems.map((product) => (
              <Card
                key={`${product.id}-${product.selectedSize.size || "size"}`}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  mb: 2,
                  p: 2,
                  alignItems: "center",
                }}
              >
                {/* Product Image */}
                <CardMedia
                  component="img"
                  image={product.imageUrl}
                  alt={product.title}
                  sx={{
                    width: { xs: "100%", sm: 150 },
                    height: 150,
                    objectFit: "contain",
                    borderRadius: 2,
                    mb: { xs: 2, sm: 0 },
                  }}
                />

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    ml: { xs: 0, sm: 2 },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    {product.title}
                  </Typography>
                  {product.selectedSubCategory && (
                    <Typography variant="body2" color="textSecondary">
                      Subcategory: {product.selectedSubCategory}
                    </Typography>
                  )}
                  <Typography variant="body2" color="textSecondary">
                    Size: {product.selectedSize.size || "N/A"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price: ${product.selectedPrice.amount.toFixed(2)}{" "}
                    {product.selectedPrice.currency}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Total: $
                    {(product.selectedPrice.amount * product.quantity).toFixed(
                      2
                    )}
                  </Typography>
                </Box>

                {/* Quantity + Remove */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm: "flex-end" },
                    mt: { xs: 2, sm: 0 },
                    ml: { xs: 0, sm: "auto" },
                  }}
                >
                  <Button
                    onClick={() =>
                      incrementQuantity(
                        product.id,
                        product.selectedSize.size || ""
                      )
                    }
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 40 }}
                  >
                    +
                  </Button>
                  <Typography component="span" sx={{ mx: 1 }}>
                    {product.quantity}
                  </Typography>
                  <Button
                    onClick={() =>
                      decrementQuantity(
                        product.id,
                        product.selectedSize.size || ""
                      )
                    }
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 40, mr: 2 }}
                  >
                    –
                  </Button>

                  <IconButton
                    onClick={() =>
                      removeFromCart(
                        product.id,
                        product.selectedSize.size || ""
                      )
                    }
                    color="error"
                    aria-label="Remove from cart"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Card>
            ))}

            {/* Divider + Summary */}
            {/* Divider + Summary */}
            <Divider sx={{ my: 3 }} />

            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Total: ${totalPrice.toFixed(2)}{" "}
                {cartItems[0]?.selectedPrice.currency || ""}
              </Typography>

              {/* Button Container */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Button
                  onClick={clearCart}
                  color="secondary"
                  variant="contained"
                  sx={{
                    backgroundColor: "lightBlue.main",
                    "&:hover": {
                      backgroundColor: "lightBlue.light",
                    },
                  }}
                >
                  Clear Cart
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    backgroundColor: "rose.main",
                    "&:hover": {
                      backgroundColor: "rose.light",
                    },
                  }}
                  onClick={handleProceedToCheckout}
                >
                  Proceed to Checkout
                </Button>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default Cart;
