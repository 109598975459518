// src/components/Cart.tsx

import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { CartItem } from "../../admin/auth/types";

export const calculateTotal = (cart: CartItem[]): number => {
  return cart.reduce((sum, item) => {
    const amount =
      item.selectedPrice.amount !== undefined ? item.selectedPrice.amount : 0;
    return sum + amount * item.quantity;
  }, 0);
};

const Cart: React.FC = () => {
  const {
    cartItems,
    removeFromCart,
    clearCart,
    incrementQuantity,
    decrementQuantity,
  } = useCart();

  const navigate = useNavigate(); // Used in handleProceedToCheckout
  const totalPrice = calculateTotal(cartItems);

  const handleProceedToCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
      <CardContent sx={{ pt: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 500,
            letterSpacing: ".3rem",
            textAlign: "center",
            pt: 8,
          }}
        >
          Cart
        </Typography>

        {cartItems.length > 0 ? (
          <List sx={{ mt: 4 }}>
            {cartItems.map((product) => (
              <ListItem
                key={`${product.id}-${product.selectedSize.size || "size"}`}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  mb: 4,
                  borderBottom: "1px solid #ccc",
                  pb: 2,
                }}
              >
                {/* Product Image */}
                <Box
                  sx={{
                    mb: { xs: 2, md: 0 },
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={product.imageUrl}
                    alt={product.title}
                    width="150"
                    height="150"
                    style={{ objectFit: "contain", borderRadius: "8px" }}
                  />
                </Box>

                {/* Product Details */}
                <ListItemText
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                    flexGrow: 1,
                    mb: { xs: 2, md: 0 },
                  }}
                  primary={
                    <>
                      <Typography variant="h6">{product.title}</Typography>
                      {/* Display Subcategory */}
                      {product.selectedSubCategory && (
                        <Typography variant="body2" color="textSecondary">
                          Subcategory: {product.selectedSubCategory}
                        </Typography>
                      )}
                      <Typography variant="body2" color="textSecondary">
                        Size: {product.selectedSize.size || "N/A"} | Quantity:{" "}
                        {product.quantity}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Price: ${product.selectedPrice.amount.toFixed(2)}{" "}
                        {product.selectedPrice.currency}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Total: $
                        {(
                          product.selectedPrice.amount * product.quantity
                        ).toFixed(2)}
                      </Typography>
                    </>
                  }
                />

                {/* Quantity Buttons */}
                <Box sx={{ textAlign: "center", mb: { xs: 2, md: 0 } }}>
                  <Button
                    onClick={() =>
                      incrementQuantity(
                        product.id,
                        product.selectedSize.size || ""
                      )
                    }
                    aria-label="Increase quantity"
                    variant="outlined"
                    sx={{ minWidth: "40px", px: 1 }}
                  >
                    +
                  </Button>
                  <Typography component="span" sx={{ mx: 1 }}>
                    {product.quantity}
                  </Typography>
                  <Button
                    onClick={() =>
                      decrementQuantity(
                        product.id,
                        product.selectedSize.size || ""
                      )
                    }
                    aria-label="Decrease quantity"
                    variant="outlined"
                    sx={{ minWidth: "40px", px: 1 }}
                  >
                    -
                  </Button>
                </Box>

                {/* Remove Button */}
                <IconButton
                  onClick={() =>
                    removeFromCart(product.id, product.selectedSize.size || "")
                  }
                  color="error"
                  aria-label="Remove from cart"
                  sx={{ alignSelf: { xs: "center", md: "flex-start" } }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: "center", mt: 4 }}
          >
            Cart is currently empty.
          </Typography>
        )}

        {/* Cart Actions */}
        {cartItems.length > 0 && (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Total: ${totalPrice.toFixed(2)} {cartItems[0]?.selectedPrice.currency}
            </Typography>
            <Button
              onClick={clearCart}
              color="secondary"
              variant="contained"
              sx={{
                mb: 2,
                width: "100%",
                backgroundColor: "lightBlue.main",
                "&:hover": {
                  backgroundColor: "lightBlue.light",
                },
              }}
            >
              Clear Cart
            </Button>
            <Button
              type="button"
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "rose.main",
                "&:hover": {
                  backgroundColor: "rose.light",
                },
              }}
              onClick={handleProceedToCheckout}
            >
              Proceed to Checkout
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Cart;
