// src/components/OrderSummary.tsx

import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Avatar,
  Grid,
} from "@mui/material";
import { CartItem } from "../../admin/auth/types";
import { useCart } from "../../Context/CartContext";


const OrderSummary: React.FC = () => {
  const { cartItems } = useCart();

  // Function to calculate total price
  const calculateTotal = (): number => {
    return cartItems.reduce((sum, item) => {
      return sum + item.selectedPrice.amount * item.quantity;
    }, 0);
  };

  return (
    <Box
      sx={{
        pt: 8,
        p: 2,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        backgroundColor: "#fafafa",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Order Summary
      </Typography>
      <List disablePadding>
        {cartItems.map((item: CartItem) => (
          <React.Fragment key={`${item.id}-${item.selectedSize.size}`}>
            <ListItem sx={{ py: 1, px: 0 }}>
              <Grid container spacing={2} alignItems="center">
                {/* Product Image */}
                <Grid item xs={3}>
                  <Avatar
                    variant="square"
                    src={item.imageUrl}
                    alt={item.title}
                    sx={{ width: 64, height: 64 }}
                  />
                </Grid>
                {/* Product Details */}
                <Grid item xs={6}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Subcategory: {item.selectedSubCategory}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {` | Size: ${item.selectedSize.size}`}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary">
                            {` | Quantity: ${item.quantity}`}
                          </Typography>
                          
                      </>
                    }
                  />
                </Grid>
                {/* Price and Quantity */}
                <Grid item xs={3}>
                  <Typography variant="body2">
                    ${item.selectedPrice.amount.toFixed(2)} x {item.quantity}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    ${(item.selectedPrice.amount * item.quantity).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        {/* Total Price */}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${calculateTotal().toFixed(2)}
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

export default OrderSummary;
