// src/components/Appbar/CheckoutNavbar.tsx

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the arrow icon
import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LogoOne from "../../../assets/LogoOne.png";

const CheckoutNavbar: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleBackToCart = () => {
    navigate("/cart");
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: theme.palette.lightBlue.light }}>
      <Toolbar>
        {/* Back to Cart IconButton */}
        <IconButton
          onClick={handleBackToCart}
          sx={{
            color: theme.palette.rose.main,
            marginRight: theme.spacing(2),
          }}
          aria-label="Back to Cart"
        >
          <ArrowBackIcon />
          {/* Optionally, include ShoppingCartIcon next to the arrow */}
          {/* <ShoppingCartIcon /> */}
        </IconButton>
        {/* Logo and Title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img
              src={LogoOne}
              alt="The Alpine Studio Logo"
              style={{
                height: "35px",
                marginRight: theme.spacing(1),
              }}
            />
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontWeight: 400,
                letterSpacing: ".3rem",
                color: theme.palette.lightBlue.dark,
                fontSize: {
                  xs: "1rem",
                  sm: "1.4rem",
                  md: "1.6rem",
                  lg: "1.8rem",
                  xl: "2.0rem",
                },
                fontFamily: theme.typography.fontFamily,
              }}
            >
              The Alpine Studio
            </Typography>
          </Link>
        </Box>
        {/* Empty Box for Spacing */}
        <Box sx={{ width: "120px" }} /> {/* Adjust width as needed */}
      </Toolbar>
    </AppBar>
  );
};

export default CheckoutNavbar;
