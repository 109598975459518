// src/pages/MainLayout.tsx

import React from "react";
import AppBar from "../Components/Appbar/AppBar";
import Footer from "./Footer";
import { useLocation, Outlet } from "react-router-dom";

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paymentStatus = () => {
    if (queryParams.get("success") === "true") {
      return <div>Payment succeeded! Thank you for your purchase.</div>;
    }
    if (queryParams.get("canceled") === "true") {
      return <div>Payment was canceled.</div>;
    }
    return null;
  };


  return (
    <div>
      <AppBar />
      {/* <MaintenanceBanner /> */}

      {paymentStatus()}
      <Outlet /> 
      
      <Footer />
    </div>
  );
};
