import React from 'react';
import ResponsiveAppBar from "../Components/Appbar/AppBar";
import Cart from '../Components/Cart/Cart';

export const CartLayout: React.FC = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Cart />
        </>
    );
};
