// src/context/CartContext.tsx

import {
  CartItem,
  CheckoutEvent,
} from "../admin/auth/types"; // Adjust the import path accordingly
import { configStripeCheckout } from "../admin/auth/aws-config";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";

interface Response {
  clientSecret?: string;
  error?: string;
}

interface CartContextType {
  cartItems: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (id: string, sizeValue: string) => void;
  clearCart: () => void;
  incrementQuantity: (productId: string, sizeValue: string) => void;
  decrementQuantity: (productId: string, sizeValue: string) => void;
  createPaymentIntent: (checkoutEvent: CheckoutEvent) => Promise<string | null>;
  clientSecret: string | undefined;
  setClientSecret: (secret: string | undefined) => void;
}

export const CartContext = createContext<CartContextType | undefined>(
  undefined
);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item: CartItem) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (i) =>
          i.id === item.id &&
          i.selectedSize.size === item.selectedSize.size &&
          i.selectedSubCategory === item.selectedSubCategory
      );
      if (existingItem) {
        return prevItems.map((i) =>
          i.id === item.id &&
          i.selectedSize.size === item.selectedSize.size &&
          i.selectedSubCategory === item.selectedSubCategory
            ? { ...i, quantity: i.quantity + item.quantity }
            : i
        );
      } else {
        return [...prevItems, item];
      }
    });
  };

  const createPaymentIntent = async (
    checkoutEvent: CheckoutEvent
  ): Promise<string | null> => {
    try {
      const response = await fetch(
        configStripeCheckout.API_ENDPOINT_STRIPE_CHECKOUT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkoutEvent),
        }
      );

      const data: Response = await response.json();

      if (response.status === 200 && data.clientSecret) {
        setClientSecret(data.clientSecret);
        return data.clientSecret;
      } else {
        alert("Checkout failed. Please try again.");
        return null;
      }
    } catch (error: any) {
      console.error("Checkout Error:", error);
      alert(`Checkout failed: ${error.message || "Unknown error"}`);
      return null;
    }
  };

  const removeFromCart = (id: string, sizeValue: string) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) => !(item.id === id && item.selectedSize.size === sizeValue)
      )
    );
  };

  const incrementQuantity = (productId: string, sizeValue: string) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === productId && item.selectedSize.size === sizeValue) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      })
    );
  };

  const decrementQuantity = (productId: string, sizeValue: string) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === productId && item.selectedSize.size === sizeValue) {
          return { ...item, quantity: Math.max(item.quantity - 1, 1) };
        }
        return item;
      })
    );
  };

  const clearCart = () => {
    setCartItems([]);
    setClientSecret(undefined);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        clearCart,
        createPaymentIntent,
        clientSecret,
        setClientSecret,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
