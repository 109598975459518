// src/Components/Products/ProductDetails.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Grid,
  Paper,
  IconButton,
  TextField,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { Product, Size, Price, CartItem } from "../../admin/auth/types";
import { useCart } from "../../Context/CartContext";
import { useProducts } from "../../Context/ProductContext";
import { toast } from "react-toastify";
import { Add, Remove } from "@mui/icons-material";
import FullScreenImageDialog from "./FullScreenImageDialog";

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToCart } = useCart();
  const { fetchProductById } = useProducts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedSize, setSelectedSize] = useState<Size | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(
    null
  );
  const [availableSizes, setAvailableSizes] = useState<Size[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const getProduct = async () => {
      if (!id) {
        setError("No product ID provided.");
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const fetchedProduct = await fetchProductById(id);
        setProduct(fetchedProduct);

        if (fetchedProduct) {
          const { subcategories, sizes } = fetchedProduct;

          if (subcategories && subcategories.length > 0) {
            setSelectedSubcategory(subcategories[0]);
            const subcategorySizes = sizes.filter((size) =>
              isSizeRelevant(subcategories[0], size)
            );
            setAvailableSizes(subcategorySizes);
            setDefaultSize(subcategorySizes);
          } else {
            setSelectedSubcategory(null);
            setAvailableSizes(sizes);
            setDefaultSize(sizes);
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
          console.error("Error fetching product:", err.message);
        } else {
          setError("An unknown error occurred.");
          console.error("Unknown error fetching product");
        }
      } finally {
        setIsLoading(false);
      }
    };

    getProduct();
  }, [id, fetchProductById]);

  // Determine if a size is relevant to a subcategory
  const isSizeRelevant = (subcategory: string, size: Size): boolean => {
    if (subcategory === "Shirts") {
      return ["XS", "S", "M", "L", "XL"].includes(size.size || "");
    } else if (subcategory === "Hoodies") {
      return ["S", "M", "L", "XL", "XXL"].includes(size.size || "");
    }
    return true;
  };

  // Set the default size based on available options
  const setDefaultSize = (subcategorySizes: Size[]) => {
    if (subcategorySizes.length > 0) {
      const stickerSize = subcategorySizes.find(
        (size) => size.size === "Sticker"
      );
      if (stickerSize) {
        setSelectedSize(stickerSize);
      } else {
        setSelectedSize(subcategorySizes[0]);
      }
    } else {
      setSelectedSize(null);
    }
  };

  // Handler for subcategory change
  const handleSubcategoryChange = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const newSubcategory = e.target.value;
    setSelectedSubcategory(newSubcategory);

    if (product) {
      const subcategorySizes = product.sizes.filter((size) =>
        isSizeRelevant(newSubcategory, size)
      );
      setAvailableSizes(subcategorySizes);
      setDefaultSize(subcategorySizes);
    }
  };

  // Handler for size change
  const handleSizeChange = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const sizeValue = e.target.value;
    const selected = availableSizes.find((s) => s.size === sizeValue);
    setSelectedSize(selected || null);
  };

  const handleAddToCart = () => {
    if (!product) {
      toast.error("Product information is not available.");
      return;
    }

    if (product.sold) {
      toast.error("This product is sold out.");
      return;
    }

    if (
      Array.isArray(product.subcategories) &&
      product.subcategories.length > 1 &&
      !selectedSubcategory
    ) {
      toast.error("Please select a subcategory before adding to cart.");
      return;
    }

    if (!selectedSize) {
      toast.error("Please select a size before adding to cart.");
      return;
    }

    const cartItem: CartItem = {
      id: product.id,
      title: product.title,
      price: selectedSize.price.amount ?? 0,
      imageUrl: product.imageUrl,
      selectedSubCategory: selectedSubcategory || "",
      selectedSize: selectedSize,
      selectedPrice: {
        amount: selectedSize.price.amount ?? 0,
        currency: selectedSize.price.currency,
      },
      quantity: quantity,
    };

    addToCart(cartItem);
    toast.success(`${product.title} has been added to your cart!`);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!product) {
    return null;
  }

  // Determine selected price
  let selectedPrice: Price | null = null;
  if (selectedSize) {
    selectedPrice = {
      amount: selectedSize.price.amount ?? 0,
      currency: selectedSize.price.currency,
    };
  }

  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  return (
    <>
      {/* Full-screen image dialog */}
      <FullScreenImageDialog
        imageUrl={product.imageUrl}
        alt={product.title}
        open={isImageOpen}
        onClose={() => setIsImageOpen(false)}
      />
      // Outer container: full-width blue background with flex centering
      <Box
        sx={{
          backgroundColor: theme.palette.lightCyan.light,
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: { xs: 2, md: 4 },
          pt: { xs: 8, md: 0 }, // extra top padding for mobile
        }}
      >
        {/* Inner container: restricts width and adds vertical padding */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px", // or your desired maximum width
            py: 4,
          }}
        >
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignContent="center"
          >
            {/* Image Section */}
            <Grid item xs={12} md={6} alignContent="center">
              <Paper
                elevation={6}
                sx={{
                  p: 2,
                  textAlign: "center",
                  borderRadius: theme.shape.borderRadius,
                  overflow: "hidden",
                  transition: "transform 0.3s ease",
                  "&:hover": { transform: "scale(1.02)" },
                }}
              >
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src =
                      "/path-to-placeholder-image.jpg";
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                    borderRadius: theme.shape.borderRadius,
                  }}
                  onClick={handleImageClick}
                />
              </Paper>
            </Grid>

            {/* Product Details Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: theme.palette.lightBlue.dark, fontWeight: 600 }}
                >
                  {product.title}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: theme.palette.text.secondary, lineHeight: 1.6 }}
                >
                  {product.description}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: theme.palette.text.primary }}
                >
                  Category: {product.category}
                </Typography>

                {product.sold && (
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ mt: 2, color: theme.palette.error.main }}
                  >
                    Sold Out
                  </Typography>
                )}

                {/* Subcategory Selector */}
                {product.subcategories && product.subcategories.length > 0 && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 2,
                      "& .MuiSelect-select": {
                        color: theme.palette.lightBlue.dark,
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.lightBlue.main,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.rose.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.lightBlue.dark,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme.palette.lightBlue.dark,
                      },
                    }}
                  >
                    <InputLabel id="subcategory-selector-label">
                      Select Subcategory
                    </InputLabel>
                    <Select
                      labelId="subcategory-selector-label"
                      id="subcategory-selector"
                      value={selectedSubcategory || ""}
                      onChange={handleSubcategoryChange}
                      label="Select Subcategory"
                      aria-label="Select product subcategory"
                    >
                      {product.subcategories.map((subcategory, index) => (
                        <MenuItem
                          key={`${product.id}-subcategory-${subcategory}-${index}`}
                          value={subcategory}
                          sx={{ color: theme.palette.lightBlue.dark }}
                        >
                          {subcategory}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* Size Selector */}
                {availableSizes.length > 0 && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 2,
                      "& .MuiSelect-select": {
                        color: theme.palette.lightBlue.dark,
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.lightBlue.main,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.rose.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.lightBlue.dark,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme.palette.lightBlue.dark,
                      },
                    }}
                  >
                    <InputLabel id="size-selector-label">
                      Select Size
                    </InputLabel>
                    <Select
                      labelId="size-selector-label"
                      id="size-selector"
                      value={selectedSize?.size || ""}
                      onChange={handleSizeChange}
                      label="Select Size"
                      aria-label="Select product size or sticker"
                    >
                      {availableSizes.map((size, index) => (
                        <MenuItem
                          key={`${product.id}-size-${size.size}-${index}`}
                          value={size.size}
                          sx={{ color: theme.palette.lightBlue.dark }}
                        >
                          {size.size}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* Display Selected Price */}
                {selectedPrice && (
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ mt: 2, color: theme.palette.lightBlue.dark }}
                  >
                    Price:{" "}
                    {formatPrice(selectedPrice.amount, selectedPrice.currency)}
                  </Typography>
                )}

                {/* Add to Cart Button */}
                <Button
                  variant="contained"
                  disabled={product.sold}
                  fullWidth
                  sx={{
                    mt: 3,
                    py: 1.5,
                    fontWeight: "bold",
                    bgcolor: theme.palette.rose.main,
                    borderRadius: 2,
                    boxShadow: theme.shadows[3],
                    transition:
                      "background-color 0.3s ease, transform 0.3s ease",
                    "&:hover": {
                      backgroundColor: theme.palette.rose.light,
                      transform: "translateY(-2px)",
                      boxShadow: theme.shadows[4],
                    },
                  }}
                  onClick={handleAddToCart}
                >
                  {product.sold ? "Sold Out" : "Add to Cart"}
                </Button>

                {/* Quantity Selector */}
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <IconButton
                    onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
                    aria-label="Decrease quantity"
                  >
                    <Remove />
                  </IconButton>
                  <TextField
                    value={quantity}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      setQuantity(value >= 1 ? value : 1);
                    }}
                    inputProps={{ min: 1, style: { textAlign: "center" } }}
                    sx={{ width: 60 }}
                    variant="outlined"
                    size="small"
                  />
                  <IconButton
                    onClick={() => setQuantity((prev) => prev + 1)}
                    aria-label="Increase quantity"
                  >
                    <Add />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

// Helper function to format the price
function formatPrice(amount: number, currency: string): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  }).format(amount);
}

export default ProductDetail;
