// src/components/Appbar/ResponsiveAppBar.tsx

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LogoOne from "../../../assets/LogoOne.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InfoIcon from "@mui/icons-material/Info"; // for About
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"; // for Gallery
import ContactIcon from "@mui/icons-material/ContactPage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { ListItemIcon, ListItemText } from "@mui/material";

interface Page {
  title: string;
  path: string; // Changed from 'id' to 'path'
  icon: JSX.Element;
}

const pages: Page[] = [
  {
    title: "About",
    path: "/about",
    icon: (
      <InfoIcon
        sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
      />
    ),
  },
  {
    title: "Art Gallery",
    path: "/art-gallery",
    icon: (
      <PhotoLibraryIcon
        sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
      />
    ),
  },
  {
    title: "Contact",
    path: "/contact",
    icon: (
      <ContactIcon
        sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
      />
    ),
  },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleNavigation = (path: string) => {
    navigate(path);
    handleCloseNavMenu(); // Close the menu after navigation
  };

  const { cartItems } = useCart();
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <AppBar position="fixed" sx={{ bgcolor: "lightBlue.light" }}>
      <Container maxWidth="xl" sx={{ color: "rose.dark" }}>
        <Toolbar disableGutters>
          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "rose.main" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.path}
                  onClick={() => handleNavigation(page.path)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "lightBlue.main",
                      color: "rose.light",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    {React.cloneElement(page.icon, { color: "inherit" })}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: pathname === page.path ? "bold" : "normal",
                          letterSpacing: ".05rem",
                        }}
                      >
                        {page.title}
                      </Typography>
                    }
                  />
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Logo and Title for Mobile */}
          <Link to="/">
            <img
              src={LogoOne}
              height={35}
              onClick={() => navigate("/")}
              alt="The Alpine Studio Logo"
              style={{ cursor: "pointer", paddingRight: "10px" }}
            />
          </Link>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 400,
              letterSpacing: ".3rem",
              color: "lightBlue.dark",
              textDecoration: "none",
              fontSize: {
                xs: ".8rem",
                sm: "1.4rem",
                md: "1.6rem",
                lg: "1.8rem",
                xl: "1.10rem",
              },
            }}
          >
            The Alpine Studio
          </Typography>

          {/* Desktop Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.path}
                onClick={() => handleNavigation(page.path)}
                sx={{
                  my: 2,
                  color: pathname === page.path ? "rose.main" : "lightBlue.dark",
                  display: "block",
                  cursor: "pointer",
                  fontWeight: 400,
                  letterSpacing: ".3rem",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          {/* Shopping Cart */}
          <Box sx={{ flexGrow: 0 }}>
            <Link to="/cart" style={{ textDecoration: "none" }}>
              <IconButton color="inherit" aria-label="Open cart">
                {totalItems > 0 && (
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#554348",
                      paddingRight: "2px",
                      fontWeight: "bold",
                    }}
                  >
                    {totalItems}
                  </span>
                )}
                <ShoppingCartIcon style={{ color: "#554348" }} />
              </IconButton>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
