// src/pages/MainLayout.tsx

import React from "react";
import AppBar from "../Components/Appbar/AppBar";
import Footer from "./Footer";
import { useLocation, Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paymentStatus = () => {
    if (queryParams.get("success") === "true") {
      return <div>Payment succeeded! Thank you for your purchase.</div>;
    }
    if (queryParams.get("canceled") === "true") {
      return <div>Payment was canceled.</div>;
    }
    return null;
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",     // full viewport height
        backgroundColor: "lightBlue.main"
      }}
    >
      {/* Your AppBar (header) */}
      <AppBar />

      {/* Payment status message (if any) */}
      {paymentStatus()}

      {/* Main content area grows to push footer down */}
      <Box sx={{ flex: 1 }}>
        <Outlet />
      </Box>

      {/* Footer pinned at bottom */}
      <Footer />
    </Box>
  );
};
