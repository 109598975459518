// src/App.tsx

import React, { Suspense, lazy } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import { CartProvider } from "./Context/CartContext";
import { CartLayout } from "./Pages/CartLouyout";
import { MainLayout } from "./Pages/MainLayout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS first
import "./App.css"; // Then import your custom CSS
import theme from "./Context/theme";
import PaymentStatus from "./Pages/PaymentStatus";
import Checkout from "./Components/Cart/Checkout";
import ProductDetail from "./Components/Products/ProductDetails";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { ProductProvider } from "./Context/ProductContext";
import MaintenanceBanner from "./Components/Maintenance/MaintenanceBanner";

// Lazy load pages for code splitting
const Landing = lazy(() => import("./Pages/Landing"));
const About = lazy(() => import("./Pages/About"));
const Shop = lazy(() => import("./Pages/Shop"));
const Contact = lazy(() => import("./Pages/Contact"));

let key = process.env.REACT_APP_PUBLIC_KEY;

if (!key) {
  throw new Error("REACT_APP_PUBLIC_KEY must be defined");
}

const stripePromise = loadStripe(key);

function App() {


  return (
    <Elements stripe={stripePromise}>
      <ProductProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <CartProvider>
            <ErrorBoundary>
              <MaintenanceBanner />

              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/cart" element={<CartLayout />} />
                  <Route path="/success" element={<PaymentStatus />} />
                  <Route path="/canceled" element={<PaymentStatus />} />
                  <Route path="/checkout" element={<Checkout />} />

                  {/* Wrap all main routes with MainLayout */}
                  <Route path="/" element={<MainLayout />}>
                    {/* Child routes with relative paths */}
                    <Route path="product/:id" element={<ProductDetail />} />
                    <Route index element={<Landing color="primary" />} />
                    <Route path="about" element={<About color="primary" />} />
                    <Route path="shop" element={<Shop />} />
                    <Route path="contact" element={<Contact />} />
                  </Route>

                  {/* Wildcard Route: Redirect unknown paths to Landing */}
                  <Route path="*" element={<Landing color="primary" />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </CartProvider>

          {/* ToastContainer with dynamic class names */}
          <ToastContainer
            position="bottom-left"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover

          />
        </ThemeProvider>
      </ProductProvider>
    </Elements>
  );
}

export default App;
