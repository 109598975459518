import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Box } from '@mui/material';

const isUnderMaintenance = false; // Set this based on your maintenance status

const MaintenanceBanner: React.FC = () => {
  if (!isUnderMaintenance) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", position: "fixed", top: "70px", left: 0, zIndex: 1000 }}>
      <Alert severity="warning" sx={{ textAlign: "center", fontWeight: "bold" }}>
        <AlertTitle sx={{ alignItems: "center", justifyContent: "center"}}>🚧 Maintenance in Progress 🚧</AlertTitle>
        We're currently performing updates. Some features may be unavailable.
      </Alert>
    </Box>
  );
};

export default MaintenanceBanner;
